import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';

import SvgIcon from '../../components/svg-icon/svg-icon';
import * as styles from './back-to-top.module.css';

const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};

const BackToTop = () => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    useEffect(() => {
        const button = buttonRef.current!;

        window.addEventListener('scroll', () => {
            if (window.pageYOffset > window.innerHeight) {
                button.classList.add(styles.active);
            } else {
                button.classList.remove(styles.active);
            }
        });
    }, []);

    return (
        <div className={clsx('fixed bottom-5 right-5 z-40')}>
            <button
                onClick={handleBackToTop}
                ref={buttonRef}
                className={styles.button}>
                <div
                    className={clsx(
                        'w-7 h-7 min-w-5 min-h-5 flex items-center justify-center relative z-40',
                        'rounded-full overflow-hidden bg-primary-200'
                    )}>
                    <SvgIcon
                        icon="arrow-right"
                        color="white"
                        className={clsx('h-3 transform -rotate-90')}
                    />
                </div>
            </button>
        </div>
    );
};

export default BackToTop;
