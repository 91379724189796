import axios from 'axios';
import clsx from 'clsx';
import { Formik, Form, Field } from 'formik';
import React, { FC, useState } from 'react';
import * as yup from 'yup';

import { TextArea } from '../../components/inputs/text-area';
import { TextField } from '../../components/inputs/text-field';
import { Grid } from '../../components/layout/grid';
import Loading from '../../components/loading/loading';
import { Modal } from '../../components/modal';
import { Button } from '../../components/navigation/button';
import { SuccessMessage } from '../../components/success-message';

export interface ProductDemoFormProps
    extends React.FormHTMLAttributes<HTMLFormElement> {}

const validationSchema = yup.object({
    Name: yup
        .string()
        .required('This field is required')
        .min(2, 'Invalid name'),
    Email: yup
        .string()
        .required('This field is required')
        .email('Invalid email'),
    Contact: yup
        .number()
        .min(5, 'Invalid contact number')
        .typeError('Invalid contact number'),
    'Date of Visit': yup.date().required('This field is required'),
    Organization: yup.string().required('This field is required'),
    'Product/s to Test': yup.string().required('This field is required')
});

const ProductDemoForm: FC<ProductDemoFormProps> = ({ className, ...props }) => {
    const [serverState, setServerState] = useState<any>({
        submitted: false,
        ok: '',
        msg: ''
    });

    const handleServerResponse = (
        submitted: boolean,
        ok: boolean,
        msg: string
    ) => {
        setServerState({ submitted, ok, msg });
    };

    const handleOnSubmit = (values: any, actions: any) => {
        axios({
            method: 'POST',
            url: 'https://formspree.io/p/1673518345372040202/f/productDemo',
            data: values
        })
            .then(() => {
                actions.setSubmitting(false);
                actions.resetForm();
                handleServerResponse(
                    true,
                    true,
                    'Thank you for contacting us. We will be in touch with you shortly.'
                );
            })
            .catch((error) => {
                actions.setSubmitting(false);
                handleServerResponse(true, false, error.response.data.error);
            });
    };
    return (
        <>
            <Formik
                initialValues={{
                    Name: '',
                    Email: '',
                    Contact: '',
                    'Date of Visit': '',
                    Organization: '',
                    'Product/s to Test': ''
                }}
                onSubmit={handleOnSubmit}
                validationSchema={validationSchema}>
                {({ isSubmitting, errors, touched }) => {
                    return (
                        <Form className={clsx(className)} {...props} noValidate>
                            <Grid
                                container
                                base={false}
                                defaultGap={false}
                                className={clsx('gap-2', 't-sm:grid-cols-6')}>
                                <Grid
                                    item
                                    className={clsx(
                                        't-sm:col-span-3',
                                        'l-lg:col-span-2'
                                    )}>
                                    <Field
                                        id="name"
                                        name="Name"
                                        label="Name"
                                        error={errors.Name && touched.Name}
                                        as={TextField}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    className={clsx(
                                        't-sm:col-span-3',
                                        'l-lg:col-span-2'
                                    )}>
                                    <Field
                                        type="email"
                                        id="email"
                                        name="Email"
                                        label="Email"
                                        error={errors.Email && touched.Email}
                                        as={TextField}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    className={clsx(
                                        't-sm:col-span-3',
                                        'l-lg:col-span-2'
                                    )}>
                                    <Field
                                        id="contact"
                                        name="Contact"
                                        label="Contact"
                                        error={
                                            errors.Contact && touched.Contact
                                        }
                                        as={TextField}
                                    />
                                </Grid>

                                <Grid item className={clsx('t-sm:col-span-3')}>
                                    <Field
                                        type="date"
                                        id="visit"
                                        name="Date of Visit"
                                        label="When would you like us to visit?"
                                        error={
                                            errors['Date of Visit'] &&
                                            touched['Date of Visit']
                                        }
                                        as={TextField}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    className={clsx(
                                        't-sm:col-span-full',
                                        'l-lg:col-span-3'
                                    )}>
                                    <Field
                                        id="organization"
                                        name="Organization"
                                        label="Organization"
                                        error={
                                            errors.Organization &&
                                            touched.Organization
                                        }
                                        as={TextField}
                                    />
                                </Grid>

                                <Grid item className={clsx('t-sm:col-span-5')}>
                                    <Field
                                        id="product-test"
                                        name="Product/s to Test"
                                        label="What product/s would you like to test?"
                                        error={
                                            errors['Product/s to Test'] &&
                                            touched['Product/s to Test']
                                        }
                                        as={TextArea}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    className={clsx(
                                        'self-center justify-self-center t-sm:mt-4'
                                    )}>
                                    {isSubmitting ? (
                                        <div className="flex flex-col items-center">
                                            <Loading />
                                            <p className="text-xs font-medium uppercase text-secondary  tracking-wider ">
                                                Sending
                                            </p>
                                        </div>
                                    ) : (
                                        <Button
                                            button={true}
                                            type="submit"
                                            disabled={isSubmitting}
                                            variant="signage-with-label">
                                            Submit
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                            {/* {serverState && ( */}

                            {/* )} */}
                        </Form>
                    );
                }}
            </Formik>
            <Modal
                handleClose={() =>
                    setServerState({ ...serverState, submitted: false })
                }
                show={serverState && serverState.submitted}>
                <SuccessMessage
                    success={serverState && serverState.ok}
                    message={serverState && serverState.msg}
                />
            </Modal>
        </>
    );
};

ProductDemoForm.defaultProps = {};

export default ProductDemoForm;
