import clsx from 'clsx';
import React, { FC } from 'react';

import SvgIcon from '../svg-icon/svg-icon';
import { Heading } from '../typography/heading';
import { Paragraph } from '../typography/paragraph';

interface SuccessMessageProps {
    success: boolean;
    message: string;
}

const SuccessMessage: FC<SuccessMessageProps> = ({ success, message }) => {
    return (
        <div className={clsx('flex flex-col justify-center items-center')}>
            {success ? (
                <>
                    <SvgIcon
                        icon="check-circle"
                        className={clsx(
                            'text-success fill-current h-10 w-10 min-h-8 min-w-8'
                        )}
                    />
                    <Heading className="text-success">Message sent</Heading>
                    <Paragraph className="text-center">{message}</Paragraph>
                </>
            ) : (
                <>
                    <SvgIcon
                        icon="times-circle"
                        className={clsx(
                            'text-error fill-current h-10 w-10 min-h-8 min-w-8'
                        )}
                    />
                    <Heading className="text-error" tagName="h3">
                        Message not sent
                    </Heading>
                    <Paragraph className="text-center">
                        {message}. Please try again.
                    </Paragraph>
                </>
            )}
        </div>
    );
};

export default SuccessMessage;

SuccessMessage.defaultProps = {
    success: false
};
