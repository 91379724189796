import clsx from 'clsx';
import React, { FC, useEffect } from 'react';

import SVGIcon from '../svg-icon/svg-icon';
import * as styles from './modal.module.css';

interface ModalProps {
    handleClose: () => void;
    show: boolean;
}

const Modal: FC<ModalProps> = ({ children, handleClose, show }) => {
    useEffect(() => {
        document.addEventListener('keyup', (e) => {
            if (e.key === 'Escape') {
                handleClose();
            }
        });
    }, []);

    useEffect(() => {
        const body = document.querySelector('body')!;

        if (show) {
            body.classList.add('overflow-y-hidden');
        } else {
            body.classList.remove('overflow-y-hidden');
        }
    }, [show]);

    return (
        <div className={clsx({ [styles.active]: show }, styles.modalContainer)}>
            <div
                className={clsx(
                    'fixed bg-white p-8 z-60 top-1/2 left-1/2',
                    'max-w-8/10 w-full',
                    'm-lg:max-w-500px m-lg:w-auto',
                    styles.modalFrame
                )}>
                <button
                    className={clsx('absolute top-2 right-2 flex')}
                    onClick={handleClose}>
                    <SVGIcon icon="times" size={32} />
                </button>
                {children}
            </div>

            <div
                className={clsx(
                    'fixed inset-0 bg-primary bg-opacity-80 z-50',
                    styles.modalBackdrop
                )}
            />
        </div>
    );
};

export default Modal;
