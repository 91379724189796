import React from 'react';

import * as style from './loading.module.css';

const Loading = () => {
    return (
        <div className={style.ldsRing}>
            <div />
            <div />
            <div />
            <div />
        </div>
    );
};

export default Loading;
