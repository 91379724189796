import clsx from 'clsx';
import React, { forwardRef } from 'react';

import './styles.css';

export interface ListProps extends React.HTMLAttributes<HTMLUListElement> {
    color?: 'white' | 'dark-gray';
}

const List = forwardRef<HTMLUListElement, ListProps>(
    ({ children, className, color }, ref) => {
        return (
            <ul
                className={clsx(
                    'list',
                    `text-${color} text-opacity-80`,
                    className,
                )}
                ref={ref}>
                {children}
            </ul>
        );
    },
);

List.defaultProps = {
    color: 'dark-gray',
};

export default List;
