import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import clsx from 'clsx';
import {
    renderRichText,
    RenderRichTextData,
    ContentfulRichTextGatsbyReference
} from 'gatsby-source-contentful/rich-text';
import React, { FC } from 'react';

import { List } from '../../components/list';

interface RichTextProps extends React.HTMLAttributes<HTMLDivElement> {
    color?: 'white' | 'dark-gray';
    data: RenderRichTextData<ContentfulRichTextGatsbyReference>;
    listClassName?: string;
}

const RichText: FC<RichTextProps> = ({
    data,
    color,
    className,
    listClassName
}) => {
    const options: Options = {
        renderMark: {
            [MARKS.BOLD]: (text) => <span className="font-medium">{text}</span>
        },
        renderNode: {
            // @ts-ignore
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <p
                        className={clsx(
                            'mt-br first:mt-0 leading-loose',
                            `text-${color} text-opacity-80`
                        )}>
                        {children}
                    </p>
                );
            },
            // @ts-ignore
            [BLOCKS.LIST_ITEM]: (node, children) => {
                return <li>{children}</li>;
            },
            // @ts-ignore
            [BLOCKS.UL_LIST]: (node, children) => {
                return (
                    <List className={clsx(`text-${color}`, listClassName)}>
                        {children}
                    </List>
                );
            }
        }
    };

    return (
        <div className={clsx(className)}>
            {data && renderRichText(data, options)}
        </div>
    );
};

export default RichText;

RichText.defaultProps = {
    color: 'dark-gray'
};
