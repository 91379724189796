import clsx from 'clsx';
import { ErrorMessage } from 'formik';
import React, { forwardRef } from 'react';

export interface TextFieldProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    error?: boolean;
    id: string;
    label: string;
    rounded?: 'sm' | 'md' | 'lg' | '' | 'full';
}

const styles = {
    base:
        'p-2 w-full bg-white border border-divider-light text-dark-gray text-opacity-80'
};

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
    (
        {
            children,
            className,
            disabled,
            error,
            id,
            label,
            required,
            rounded,
            type,
            name,
            ...props
        },
        ref
    ) => {
        return (
            <>
                <label
                    className={clsx(
                        'block mb-1 text-sm text-dark-gray text-opacity-80 cursor-pointer'
                    )}
                    htmlFor={id}>
                    {label}
                </label>
                <input
                    type={type}
                    className={clsx(
                        styles.base,
                        { 'border-error': error },
                        rounded !== '' ? `rounded-${rounded}` : 'rounded',
                        className
                    )}
                    id={id}
                    ref={ref}
                    name={name}
                    {...props}
                />
                <ErrorMessage
                    //@ts-ignore
                    name={name}
                    className={clsx('text-xs font-normal text-error mt-1')}
                    component="p"
                />
            </>
        );
    }
);

TextField.defaultProps = {
    error: false,
    type: 'text',
    rounded: 'sm'
};

export default TextField;
