import clsx from 'clsx';
import { ErrorMessage } from 'formik';
import React, { forwardRef } from 'react';

export interface TextAreaProps
    extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    error?: boolean;

    id: string;
    label: string;
    rounded?: 'sm' | 'md' | 'lg' | '' | 'full';
}

const styles = {
    base:
        'p-2 w-full bg-white border border-divider-light text-dark-gray text-opacity-80'
};

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
    (
        {
            children,
            className,
            disabled,
            error,
            id,
            label,
            name,
            required,
            rounded,
            ...props
        },
        ref
    ) => {
        return (
            <>
                <label
                    className={clsx(
                        'block mb-1 text-dark-gray text-sm text-opacity-80 cursor-pointer'
                    )}
                    htmlFor={id}>
                    {label}
                </label>
                <textarea
                    className={clsx(
                        styles.base,
                        { 'border-error': error },
                        rounded !== '' ? `rounded-${rounded}` : 'rounded',
                        className
                    )}
                    id={id}
                    rows={4}
                    ref={ref}
                    name={name}
                    {...props}></textarea>
                <ErrorMessage
                    //@ts-ignore
                    name={name}
                    className={clsx('text-xs font-normal text-error mt-1')}
                    component="p"
                />
            </>
        );
    }
);

TextArea.defaultProps = {
    error: false,
    rounded: 'sm'
};

export default TextArea;
