import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';

import { productRoutes } from '../../../routes/products';
import { Container } from '../../components/layout/container';
import { Grid } from '../../components/layout/grid';
import { Section } from '../../components/layout/section';
import { Button } from '../../components/navigation/button';
import { Menu } from '../../components/navigation/menu';
import { MenuItem } from '../../components/navigation/menu-item';
import { NavLink } from '../../components/navigation/nav-link';
import { Heading } from '../../components/typography/heading';
import { Paragraph } from '../../components/typography/paragraph';
import { Logo } from '../logo';
import { ProductDemoForm } from '../product-demo-form';
import { RichText } from '../rich-text';

export interface FooterProps extends React.HTMLAttributes<HTMLElement> {}

const Footer = React.forwardRef<HTMLElement, FooterProps>(
    ({ className, ...props }, ref) => {
        const [year, setYear] = useState<string | number>('');

        useEffect(() => {
            let date = new Date();
            let year = date.getFullYear();
            setYear(year);
        }, []);

        const { productDemoText, contactInfo } = useStaticQuery(query);

        const content = {
            productDemo: {
                text: productDemoText
            },
            contactInfo: contactInfo
        };
        return (
            <>
                <Section
                    className="bg-light-gray rounded-t-md"
                    id="product-demo">
                    <Container>
                        <Grid container>
                            <Grid
                                item
                                className={clsx(
                                    't-sm:col-span-full',
                                    't-lg:col-span-5 t-lg:pr-10',
                                    'l-lg:col-span-4 l-lg:pr-0'
                                )}>
                                <Heading className="min-w-18ch">
                                    {content.productDemo.text.title}
                                </Heading>

                                <RichText
                                    data={content.productDemo.text.richText}
                                />
                            </Grid>

                            <Grid
                                item
                                className={clsx(
                                    't-sm:col-span-full',
                                    't-lg:col-span-6-13'
                                )}>
                                <ProductDemoForm />
                            </Grid>
                        </Grid>
                    </Container>
                </Section>

                <footer
                    className={clsx(
                        'pt-16 pb-6 text-sm bg-primary rounded-t-md',
                        'l-sm:pb-16'
                    )}
                    ref={ref}
                    {...props}>
                    <Container>
                        <Grid
                            container
                            defaultGap={false}
                            className="gap-x-10 gap-y-5">
                            <Grid
                                item
                                className={clsx(
                                    't-sm:col-span-full',
                                    'l-sm:col-span-1-4 l-sm:flex l-sm:flex-col l-sm:justify-between'
                                )}>
                                <Logo type="mark" />

                                <Paragraph
                                    className="text-xs hidden l-sm:block"
                                    color="white">
                                    Copyright © 2019 - {year} <br />
                                    RMG Hospital Supply, Inc. <br />
                                    All Rights Reserved.
                                </Paragraph>
                            </Grid>

                            <Grid
                                item
                                className={clsx(
                                    't-sm:col-span-4',
                                    'l-sm:col-span-5-8'
                                )}>
                                <Heading
                                    tagName="h6"
                                    color="white"
                                    className="mb-1.5 t-sm:mb-4">
                                    Our Products
                                </Heading>
                                <Menu>
                                    {productRoutes.map((item, i) => {
                                        return (
                                            <MenuItem
                                                className="text-white text-opacity-80 mb-3 max-w-25ch"
                                                key={`menu-item-${i}`}>
                                                <NavLink
                                                    to={item.path}
                                                    activeClassName="">
                                                    {item.category}
                                                </NavLink>
                                            </MenuItem>
                                        );
                                    })}
                                </Menu>
                                <Button
                                    to="https://drive.google.com/drive/folders/1rwL5t08FJZe64x2_BC64yOLB6udwYQlL"
                                    variant="text"
                                    target="_blank">
                                    Full Brochure
                                </Button>
                            </Grid>

                            <Grid
                                item
                                className={clsx(
                                    't-sm:col-span-8',
                                    'l-sm:col-span-8-13'
                                )}>
                                <Heading
                                    tagName="h6"
                                    color="white"
                                    className="mb-1.5 t-sm:mb-4">
                                    Contact Information
                                </Heading>

                                <Grid
                                    container
                                    base={false}
                                    defaultGap={false}
                                    className="t-sm:grid-cols-2 gap-x-10">
                                    <Grid item>
                                        <Paragraph
                                            className="font-medium"
                                            color="white">
                                            Address:
                                        </Paragraph>
                                        <Paragraph color="white">
                                            {content.contactInfo.address}
                                        </Paragraph>

                                        <Paragraph
                                            className="font-medium mt-5"
                                            color="white">
                                            Email:
                                        </Paragraph>
                                        <Paragraph color="white">
                                            <a
                                                href={`mailto:${content.contactInfo.email}`}>
                                                {content.contactInfo.email}
                                            </a>
                                        </Paragraph>
                                    </Grid>

                                    <Grid item>
                                        <Paragraph
                                            className="font-medium"
                                            color="white">
                                            Fax Number:
                                        </Paragraph>

                                        <div>
                                            {content.contactInfo.faxNumbers.map(
                                                (item: string, i: number) => {
                                                    return (
                                                        <Paragraph
                                                            color="white"
                                                            key={`fax-number-${i}`}>
                                                            <a
                                                                href={`tel:${item}`}>
                                                                {item}
                                                            </a>
                                                        </Paragraph>
                                                    );
                                                }
                                            )}
                                        </div>

                                        <Paragraph
                                            className="font-medium mt-5"
                                            color="white">
                                            Phone Numbers:
                                        </Paragraph>

                                        <div color="white">
                                            {content.contactInfo.phoneNumbers.map(
                                                (item: string, i: number) => {
                                                    return (
                                                        <Paragraph
                                                            color="white"
                                                            key={`phone-number-${i}`}>
                                                            <a
                                                                href={`tel:${item}`}>
                                                                {item}
                                                            </a>
                                                        </Paragraph>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Paragraph
                            className="mt-16 text-xxs l-sm:hidden"
                            color="white">
                            Copyright © 2019 - {year} RMG Hospital Supply, Inc.
                            All Rights Reserved.
                        </Paragraph>
                    </Container>
                </footer>
            </>
        );
    }
);

export default Footer;

const query = graphql`
    {
        productDemoText: contentfulComponentText(
            identifier: { eq: "product-demo" }
        ) {
            title
            richText {
                raw
            }
        }
        contactInfo: contentfulContactInformation {
            address
            email
            faxNumbers
            phoneNumbers
        }
    }
`;
