import { FormspreeProvider } from '@formspree/react';
import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { BackToTop } from '../back-to-top';
import { Footer } from '../footer';
import { Header } from '../header';

interface BaseLayoutProps {}

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
    const {
        routes: { pages }
    } = useStaticQuery(graphql`
        query {
            routes: contentfulRoutesAll {
                pages {
                    ... on ContentfulPages {
                        id
                        title
                        slug
                    }
                    ... on ContentfulRoutesCoverageAreas {
                        id
                        title
                        slug
                    }
                    ... on ContentfulRoutesProducts {
                        id
                        title
                        subMenu {
                            id
                            title
                            subCategories {
                                id
                                title
                                slug
                            }
                        }
                    }
                }
            }
        }
    `);

    return (
        <>
            <FormspreeProvider project="1673518345372040202">
                <Header routes={pages} />
                {children}
                <Footer />
                <BackToTop />
                {/* <Preloader /> */}
            </FormspreeProvider>
        </>
    );
};

export default BaseLayout;
