export const productRoutes = [
    {
        id: 'medical-disposables',
        category: 'Medical Disposables',
        path: '/products/medical-disposables/sterilizing-rolls-and-pouches',
        subCategory: [
            {
                path:
                    '/products/medical-disposables/sterilizing-rolls-and-pouches',
                url: false,
                title: 'Sterilizing Rolls and Pouches'
            },
            {
                path: '/products/medical-disposables/tubings-and-catheters',
                url: false,
                title: 'Tubings And Catheters'
            },
            {
                path: '/products/medical-disposables/disposable-syringes',
                url: false,
                title: 'Disposable Syringes'
            },
            {
                path: '/products/medical-disposables/bags',
                url: false,
                title: 'Bags'
            },
            {
                path: '/products/medical-disposables/bottles',
                url: false,
                title: 'Bottles'
            },
            {
                path: '/products/medical-disposables/orthopedic-products',
                url: false,
                title: 'Orthopedic Products'
            },
            {
                path:
                    '/products/medical-disposables/anti-bedsore-gels-and-positioners ',
                url: false,
                title: 'Anti-bedsore Gels and Positioners'
            },
            {
                path:
                    '/products/medical-disposables/infection-control-products',
                url: false,
                title: 'Infection Control and Prevention Products'
            },
            {
                path: '/products/medical-disposables/surgical-drapes',
                url: false,
                title: 'Surgical Drapes, Gowns and Protective Products'
            },
            {
                path: '/products/medical-disposables/wound-care-products ',
                url: false,
                title: 'Wound Care Products '
            },
            {
                path: '/products/medical-disposables/others',
                url: false,
                title: 'Others'
            }
        ]
    },
    {
        id: 'medical-surgical-devices',
        category: 'Medical Surgical Devices and Anesthesia Products',
        path: '/products/medical-surgical-devices/surgical-suction-devices',
        subCategory: [
            {
                path:
                    '/products/medical-surgical-devices/surgical-suction-devices',
                url: false,
                title: 'Surgical Suction Devices'
            },
            {
                path:
                    '/products/medical-surgical-devices/wound-drainage-system',
                url: false,
                title: 'Wound Drainage System'
            },
            {
                path: '/products/medical-surgical-devices/anesthesia-products',
                url: false,
                title: 'Anesthesia Products'
            },
            {
                path: '/products/medical-surgical-devices/sutures',
                url: false,
                title: 'Sutures '
            },
            {
                path: '/products/medical-surgical-devices/biopsy-needles',
                url: false,
                title: 'Biopsy Needles'
            }
        ]
    },
    {
        id: 'oxygen-oncentrators',
        category: 'Oxygen Oncentrators, Medical Gas System & Equipment',
        path: '/products/oxygen-oncentrators/medical-gas-piping-system',
        subCategory: [
            {
                path: '/products/oxygen-oncentrators/medical-gas-piping-system',
                url: false,
                title: 'Medical Gas Piping System'
            },
            {
                path: '/products/oxygen-oncentrators/mgps-secondary-equipment',
                url: false,
                title: 'MGPS Secondary Equipment and Accessories'
            },
            {
                path: '/products/oxygen-oncentrators/medical-equipment',
                url: false,
                title: 'Medical Equipment'
            }
        ]
    }
];
